import styled from "styled-components"
import "../layout.css"

export const ButtonPrimary = styled.button`
  font-family: "Nunito Sans", sans-serif;
  border: none;
  background-color: #0022e7;
  color: ${props => props.color || "white"};
  padding: ${props => props.padding || "15px 20px"};
  border-radius: ${props => props.borderRadius};
  font-weight: 700;
  outline: none;
  &:hover {
    box-shadow: 0px 0px 10px 3px #12141678;
  }
  &:focus {
    outline: none;
  }
  cursor: pointer;
  width: ${props => props.width};
`
