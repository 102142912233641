import React from "react"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import { H3, H5, P } from "../utils/Typography"
import "bootstrap/dist/css/bootstrap.css"

const BlockFeatured = styled.div`
  background-color: #0022e721;
  padding: 25px;
  cursor: pointer;
  border-right: 5px solid #0022e721;
  &:hover {
    border-right: 5px solid #0022e7;
  }
`

const Block = styled.div`
  background-color: #0022e70d;
  padding: 25px;
  cursor: pointer;
  border-right: 5px solid #0022e721;
  &:hover {
    border-right: 5px solid #0022e7;
  }
`

const verticalAlignRight = {
  margin: "10px 10px 0 0",
  textAlign: "right",
}

const verticalAlignFeatRight = {
  margin: "0px 10px 0 0",
  textAlign: "right",
}

function JobBlock(props) {
  const isNew = props.isNew
  const isFeat = props.isFeat
  return (
    <>
      {isFeat === "Yes" ? (
        <BlockFeatured>
          <Container>
            <Row>
              <Col
                lg={{ size: 7 }}
                md={{ size: 7 }}
                sm={{ size: 6 }}
                xs={{ size: 6 }}
              >
                <H5 style={{ marginBottom: "10px", fontWeight: 600 }}>
                  {props.CompanyName}
                </H5>
                <H3 style={{ marginBottom: "5px" }}>{props.JobTitle}</H3>
                <P style={{ fontWeight: 700 }}>{props.JobType}</P>
              </Col>
              <Col
                lg={{ size: 5 }}
                md={{ size: 5 }}
                sm={{ size: 6 }}
                xs={{ size: 6 }}
              >
                <div style={verticalAlignFeatRight}>
                  {isNew === "Yes" ? (
                    <>
                      <span role="img" aria-label="fire">
                        🔥
                      </span>
                      <P
                        textAlign="right"
                        style={{
                          fontWeight: 800,
                          margin: "5px",
                          color: "#0022e7",
                        }}
                      >
                        New!
                      </P>
                      <P textAlign="right" style={{ fontWeight: "800" }}>
                        {props.PostingDate}
                      </P>
                    </>
                  ) : (
                    <P
                      textAlign="right"
                      style={{ fontWeight: "800", marginTop: "25px" }}
                    >
                      <span role="img" aria-label="fire">
                        🔥
                      </span>
                      <br />
                      {props.PostingDate}
                    </P>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </BlockFeatured>
      ) : (
        <Block>
          <Container>
            <Row>
              <Col
                lg={{ size: 7 }}
                md={{ size: 7 }}
                sm={{ size: 6 }}
                xs={{ size: 6 }}
              >
                <H5 style={{ marginBottom: "10px", fontWeight: 600 }}>
                  {props.CompanyName}
                </H5>
                <H3 style={{ marginBottom: "5px" }}>{props.JobTitle}</H3>
                <P style={{ fontWeight: 700 }}>{props.JobType}</P>
              </Col>
              <Col
                lg={{ size: 5 }}
                md={{ size: 5 }}
                sm={{ size: 6 }}
                xs={{ size: 6 }}
              >
                <div style={verticalAlignRight}>
                  {isNew === "Yes" ? (
                    <>
                      <P
                        textAlign="right"
                        style={{
                          fontWeight: 800,
                          margin: "5px",
                          color: "#0022e7",
                        }}
                      >
                        New!
                      </P>
                      <P textAlign="right" style={{ fontWeight: "800" }}>
                        {props.PostingDate}
                      </P>
                    </>
                  ) : (
                    <P
                      textAlign="right"
                      style={{ fontWeight: "800", marginTop: "25px" }}
                    >
                      {props.PostingDate}
                    </P>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </Block>
      )}
    </>
  )
}
export default JobBlock
