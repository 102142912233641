import React from "react"
import styled from "styled-components"
import { H4 } from "../utils/Typography"
import { ButtonPrimary } from "../utils/Buttons"
import { Row, Col, Container } from "reactstrap"

const Main = styled.div`
  background-color: #121416;
  padding: 25px;
  text-align: center;
  margin-bottom: 50px;
`

export default class PostAJob extends React.Component {
  render() {
    return (
      <Main>
        <Container>
          <Row>
            <Col lg="8">
              <H4
                style={{
                  margin: "20px",
                  fontWeight: "700",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Looking to hire remotely? Reach{" "}
                <span style={{ fontWeight: "900" }}>thousands</span> of remote
                workers here!{" "}
                <span role="img" aria-label="confetti">
                  🎉
                </span>{" "}
              </H4>
            </Col>
            <Col lg="4">
              <a href="/postJob" target="_blank" rel="noopener noreferrer">
                <ButtonPrimary style={{ margin: "5px" }}>
                  Post a Job - $10/day
                </ButtonPrimary>
              </a>
            </Col>
          </Row>
        </Container>
      </Main>
    )
  }
}
