import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { D2, D4 } from "../components/utils/Typography"
import AllJobs from "../Lists/AllJobs"
import { Container, Row, Col } from "reactstrap"
import HeroImage from "../images/homeHero.jpg"
import Searchbox from "../components/utils/Searchbox.js"
import PostAJob from "../components/Website-Blocks/PostAJob"
import Email from "../components/Website-Blocks/Email"
import List from "../components/Listings/List"
import { OnDeltaHCard } from "../components/Website-Blocks/OnDeltaCard"

const Hero = styled.div`
  min-height: 60vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
`

const HeroCover = styled.div`
  background-color: #12141678;
  min-height: 60vh;
  padding: 0 80px;
  width: 100%;
  text-align: center;
  position: relative;
`

const MainContent = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 1rem;
`

const Header = styled.div`
  margin-top: -40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 800px;
`
const SearchBoxContainer = styled.div`
  margin-top: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`
const AllPostsContainer = styled.div`
  margin-top: 50px;
`

export default class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Home" />

        <Hero style={{ backgroundImage: `url(${HeroImage})` }}>
          <HeroCover>
            <Container>
              <Header>
                <D2 style={{ color: "white" }} textAlign="center">
                  Jobs Go Remote
                </D2>
                <D4
                  style={{
                    color: "white",
                    fontWeight: "700",
                  }}
                  textAlign="center"
                >
                  Find companies looking for <br />
                  their next <span style={{ color: "#121416" }}>
                    remote
                  </span>{" "}
                  marketer.
                </D4>
                <SearchBoxContainer>
                  <Searchbox />
                </SearchBoxContainer>
              </Header>
            </Container>
          </HeroCover>
        </Hero>
        <PostAJob />
        <Container>
          <Row>
            <Col>
              <MainContent>
                <List />
                <Email />
                <AllPostsContainer>
                  <AllJobs />
                </AllPostsContainer>
                <OnDeltaHCard />
              </MainContent>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}
