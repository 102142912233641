import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { Container, Row, Col } from "reactstrap"
import Typist from "react-typist"
import { H4 } from "./Typography"

const SearchboxObject = styled.div`
  background-color: white;
  padding: 15px;
  border-radius: 40px;
  width: 350px;
  min-height: 50px;
  font-weight: 700;
`

class Searchbox extends React.Component {
  state = {
    typing: true,
  }
  done = () => {
    this.setState({ typing: false }, () => {
      this.setState({ typing: true })
    })
  }
  render() {
    return (
      <SearchboxObject>
        <Container>
          <Row>
            <Col lg="2" xs="2" sm="2" md="2">
              <div style={{ color: "#0022e7" }}>
                <FontAwesomeIcon icon={faSearch} />
              </div>
            </Col>
            <Col lg="10" xs="10" sm="10" md="10" style={{ textAlign: "left" }}>
              <H4 style={{ margin: "0", paddingTop: "4px" }}>
                {this.state.typing && (
                  <Typist onTypingDone={this.done}>
                    <span>Growth Marketer</span>
                    <Typist.Backspace count={20} delay={1000} />
                    <span>Copywriter</span>
                    <Typist.Backspace count={20} delay={1000} />
                    <span>SEO Specialist</span>
                    <Typist.Backspace count={20} delay={1000} />
                    <span>Marketing Analyst</span>
                    <Typist.Backspace count={30} delay={1000} />
                    <span>The best marketing jobs.</span>
                    <Typist.Backspace count={30} delay={3000} />
                  </Typist>
                )}
              </H4>
            </Col>
          </Row>
        </Container>
      </SearchboxObject>
    )
  }
}

export default Searchbox
