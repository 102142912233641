import React from "react"
import styled from "styled-components"
import addToMailchimp from "gatsby-plugin-mailchimp"

import { ButtonPrimary } from "../utils/Buttons"
import { Row, Col, Container, Form, Input, Label } from "reactstrap"
import { H3, H4, H5 } from "../utils/Typography"

const Main = styled.div`
  background-color: #f1f1f1;
  padding: 40px 30px 10px;
  text-align: center;
`

export default class Email extends React.Component {
  state = {
    email: "",
    result: "",
    message: "",
  }

  _handleSubmit = async e => {
    e.preventDefault()
    const response = await addToMailchimp(this.state.email)
    const result = response.result
    const message = response.msg
    this.setState({
      result: result,
      message: message,
    })
    document.getElementById("email").value = ""
  }

  _handleEmailChange = e => {
    const target = e.target
    const value = target.value
    this.setState({
      email: value,
    })
    console.log(value)
  }

  render() {
    const inputStyles = {
      fontFamily: "Nunito Sans, sans-serif",
    }

    function MessageShow(props) {
      const result = props.result
      const message = props.message
      if (result === "success") {
        return (
          <H5
            margin={"10px 0 0 0"}
            padding={"0 0 0 3px"}
            style={{ color: "#0022e7" }}
          >
            {message}
          </H5>
        )
      } else {
        return (
          <H5
            margin={"10px 0 0 0"}
            padding={"0 0 0 3px"}
            style={{ color: "red" }}
          >
            {message}
          </H5>
        )
      }
    }

    return (
      <Main>
        <Container>
          <H3 textAlign="center" margin="0 0 10px 0">
            Email Newsletter{" "}
            <span role="img" aria-label="mailbox">
              📬
            </span>
          </H3>
          <H4 textAlign="center">
            Get weekly job opportunities sent
            <span style={{ color: "#0022e7", fontWeight: 800 }}>
              {" "}
              straight{" "}
            </span>
            to your inbox.
          </H4>
          <Form
            style={{ width: "100%", marginTop: "20px" }}
            onSubmit={this._handleSubmit}
          >
            <Row>
              <Col lg={{ size: 5, offset: 2 }} style={{ textAlign: "left" }}>
                <Label for="email">
                  <H5 margin="0" fontWeight="800">
                    Your Email
                  </H5>
                </Label>
                <Input
                  id="email"
                  type="email"
                  name="email"
                  value={this.state.email}
                  style={inputStyles}
                  placeholder="name@example.com"
                  onChange={this._handleEmailChange}
                />
                <MessageShow
                  result={this.state.result}
                  message={this.state.message}
                />
              </Col>
              <Col
                lg="3"
                style={{
                  textAlign: "left",
                  padding: "30px 0 0 20px",
                }}
              >
                <ButtonPrimary
                  id="button"
                  padding="10px 15px"
                  borderRadius="5px"
                  width="100%"
                  type="submit"
                >
                  Sign Up
                </ButtonPrimary>
              </Col>
            </Row>
          </Form>
        </Container>
      </Main>
    )
  }
}
