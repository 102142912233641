import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import JobBlock from "../components/Listings/JobBlock"
import { H3 } from "../components/utils/Typography"

const AllJobs = () => {
  const data = useStaticQuery(graphql`
    query AllJobsQuery {
      posts: allAirtable(
        filter: { data: { Status: { eq: "Publish" } } }
        sort: { fields: data___Posting_Date, order: DESC }
      ) {
        nodes {
          data {
            About_Company
            About_Opportunity
            Company_Name
            Email
            Job_Title
            Experience_Level
            Job_Type
            Link_to_Apply
            Posting_Date
            Expiration_Date
            Remote_Ok_
            Status
            Category
            New
            Featured
          }
          recordId
        }
      }
    }
  `)

  return (
    <div>
      <ul
        style={{
          listStyleType: "none",
          listStylePosition: "inside",
          margin: 0,
          paddingBottom: "50px",
        }}
      >
        <H3>
          <span style={{ color: "#0022e7" }}>All</span> Job Posts
        </H3>
        {data.posts.nodes.map((item, i) => (
          <li key={item.recordId}>
            <Link
              to={`/job/${item.recordId}`}
              style={{ textDecoration: "none", color: "#121416" }}
            >
              <JobBlock
                JobTitle={item.data.Job_Title}
                CompanyName={item.data.Company_Name}
                JobType={item.data.Job_Type}
                PostingDate={item.data.Posting_Date}
                isNew={item.data.New}
                isFeat={item.data.Featured}
              />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AllJobs
