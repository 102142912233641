import React, { useState, useEffect } from "react"
import { Row, Container } from "reactstrap"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import Select from "react-select"

import { useStaticQuery, graphql, Link } from "gatsby"

import { H3, P } from "../utils/Typography"
import JobBlock from "../Listings/JobBlock"

const TagContainer = styled.li`
  background-color: #fff;
  display: inline-block;
  border: 1px solid #0022e721;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 0;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.03);
`
const DeleteButton = styled.div`
  background-color: #0022e721;
  border: none;
  border-radius: 15px;
  width: 20px;
  height: 20px;
  font-size: 10px;
  font-weight: 600;
  margin-top: 4px;
  padding: 3px;
  text-align: center;
  font-family: Nunito Sans, sans-serif;
  &: focus {
    outline: none;
  }
`

const listStyle = {
  listStyleType: "none",
  margin: "20px 0 50px",
}

const buttonStyle = {
  background: "none",
  border: "none",
  padding: 0,
  margin: "10px 15px 10px 0",
  outline: "none",
}

const SearchBox = styled.div`
  width: 300px;
  margin-right: 20px;
  font-family: Nunito Sans, sans-serif;
`

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
}

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)

const List = props => {
  const data = useStaticQuery(graphql`
    query ListQuery {
      posts: allAirtable(
        filter: { data: { Status: { eq: "Publish" } } }
        sort: { fields: data___Posting_Date, order: DESC }
      ) {
        nodes {
          data {
            About_Company
            About_Opportunity
            Company_Name
            Email
            Job_Title
            Experience_Level
            Job_Type
            Link_to_Apply
            Posting_Date
            Expiration_Date
            Remote_Ok_
            Status
            Category
            New
            Featured
          }
          recordId
        }
      }
    }
  `)

  const allListings = data.posts.nodes

  const [state, setState] = useState({
    category: [
      "Featured",
      "Automation",
      "Copywriting",
      "Growth",
      "Paid Acquisition",
      "Product Marketing",
      "SEO/SEM",
      "Social Media",
    ],
    filtered: props.filterStartState || ["Featured"],
    listings: allListings,
  })

  console.log(state.filtered)

  const options = state.category.map(item => ({
    label: item,
    value: item,
    isDisabled: false,
  }))

  const groupedOptions = [
    {
      label: "Categories",
      options: options,
    },
  ]

  useEffect(() => {
    filterListings()
  }, [state.filtered]) // eslint-disable-line react-hooks/exhaustive-deps

  const filterListings = e => {
    let listings = allListings

    if (state.filtered.includes("Featured")) {
      listings = allListings.filter(item => {
        if (
          !state.filtered.includes("Automation") &&
          !state.filtered.includes("Copywriting") &&
          !state.filtered.includes("Growth") &&
          !state.filtered.includes("Paid Acquisition") &&
          !state.filtered.includes("Product Marketing") &&
          !state.filtered.includes("SEO/SEM") &&
          !state.filtered.includes("Social Media")
        ) {
          return item.data.Featured === "Yes"
        } else if (e === undefined) {
          return (
            item.data.Featured === "Yes" &&
            state.filtered.includes(item.data.Category[0])
          )
        } else if (
          e !== undefined &&
          (state.filtered.includes("Automation") ||
            state.filtered.includes("Copywriting") ||
            state.filtered.includes("Growth") ||
            state.filtered.includes("Paid Acquisition") ||
            state.filtered.includes("Product Marketing") ||
            state.filtered.includes("SEO/SEM") ||
            state.filtered.includes("Social Media"))
        ) {
          return (
            item.data.Featured === "Yes" &&
            state.filtered.includes(item.data.Category[0])
          )
        }
        return true
      })
    } else {
      listings = allListings.filter(item => {
        return state.filtered.includes(item.data.Category[0])
      })
    }

    setState({
      ...state,
      listings: listings,
    })
  }

  const addItem = e => {
    let list = state.filtered
    if (e !== "") {
      list.push(e)
      setState({
        ...state,
        filtered: list,
      })
    } else {
      e.classList.add("is-danger")
    }
  }

  const removeItem = item => {
    const list = state.filtered.slice()
    list.some((el, i) => {
      if (el === item) {
        list.splice(i, 1)
        return true
      }
      return null
    })

    setState({
      ...state,
      filtered: list,
    })
  }

  const handleChange = e => {
    let currentList = state.filtered
    let newList = []

    if (e.value !== "") {
      newList = currentList.filter(item => {
        const lc = item
        const filter = e.value
        return lc.includes(filter)
      })
    } else {
      newList = state.filtered
    }

    setState({
      ...state,
      filtered: newList,
    })
    addItem(e.value)
    filterListings(e.value)
  }

  return (
    <>
      <SearchBox>
        <Select
          options={groupedOptions}
          formatGroupLabel={formatGroupLabel}
          placeholder="Search Categories"
          onChange={handleChange}
          isOptionDisabled={option => {
            return state.filtered.includes(option.value)
          }}
        />
      </SearchBox>
      <ul style={listStyle}>
        {state.filtered.map(item => (
          <button
            style={buttonStyle}
            key={item}
            onClick={() => removeItem(item)}
          >
            <TagContainer key={item}>
              <Container>
                <Row>
                  {item} &nbsp;
                  <DeleteButton>x</DeleteButton>
                </Row>
              </Container>
            </TagContainer>
          </button>
        ))}
      </ul>
      {state.listings.length !== 0 ? (
        <ul
          style={{
            listStyleType: "none",
            listStylePosition: "inside",
            margin: 0,
            paddingBottom: "50px",
          }}
        >
          <H3>
            {state.filtered.map((item, index) => (
              <span style={{ color: "#0022e7" }}>
                {(index ? ", " : "") + item}
              </span>
            ))}{" "}
            Job Posts
          </H3>
          {state.listings.map(item => (
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                positionTransition
                key={item.recordId}
              >
                <li key={item.recordId}>
                  <Link
                    to={`/job/${item.recordId}`}
                    style={{
                      textDecoration: "none",
                      color: "#121416",
                    }}
                  >
                    <JobBlock
                      JobTitle={item.data.Job_Title}
                      CompanyName={item.data.Company_Name}
                      JobType={item.data.Job_Type}
                      PostingDate={item.data.Posting_Date}
                      isNew={item.data.New}
                      isFeat={item.data.Featured}
                    />
                  </Link>
                </li>
              </motion.div>
            </AnimatePresence>
          ))}
        </ul>
      ) : (
        <P style={{ margin: "20px 0 50px" }}>No results to show</P>
      )}
    </>
  )
}

export default List
