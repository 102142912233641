import React from "react"
import styled from "styled-components"
import logo from "../../images/logo-dark.png"
import { H3, H5, H4 } from "../utils/Typography"
import { ButtonPrimary } from "../utils/Buttons"
import { Container } from "reactstrap"

const Main = styled.div`
  background-color: #f1f1f1;
  padding: 30px 20px 50px 20px;
  margin: 60px 0;
  text-align: center;
`

export default class OnDeltaCard extends React.Component {
  render() {
    return (
      <Main>
        <Container>
          <img src={logo} alt="OnDelta Logo" style={{ width: "80px" }} />
          <H4 textAlign="center" style={{ fontWeight: "700" }}>
            Apply to OnDelta
          </H4>
          <H5 textAlign="left" style={{ marginBottom: "10px" }}>
            OnDelta is a 12-week online bootcamp for marketing.
            <br />
            <br />
            <b> It's completely FREE upfront.</b>
            <br />
            <br />
            <b style={{ color: "#0022e7" }}> Learn Marketing Today!</b>
          </H5>
          <a
            href={"https://www.ondelta.io/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ButtonPrimary
              style={{ fontSize: "15px", marginTop: "20px" }}
              width="100%"
            >
              {" "}
              Learn More
            </ButtonPrimary>
          </a>
        </Container>
      </Main>
    )
  }
}

const HMain = styled.div`
  background-color: #f1f1f1;
  padding: 40px 30px 50px;
  text-align: center;
`

export class OnDeltaHCard extends React.Component {
  render() {
    return (
      <HMain>
        <Container>
          <img src={logo} alt="OnDelta Logo" style={{ width: "60px" }} />
          <H3 textAlign="center" margin="0 0 10px 0">
            Apply to OnDelta!{" "}
            <span role="img" aria-label="mailbox">
              🔥
            </span>
          </H3>
          <H4 textAlign="center">
            LIVE Online classes. 3 Months Long. Free Upfront. <br />A De-Risked
            Marketing Education.
          </H4>
          <a
            href={"https://www.ondelta.io/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ButtonPrimary style={{ fontSize: "15px" }} width="50%">
              {" "}
              Learn More
            </ButtonPrimary>
          </a>
        </Container>
      </HMain>
    )
  }
}
